import React from 'react';
import { graphql } from 'gatsby';
import { HTMLRenderer, Layout, PageWrapper, SimpleSlider, HcpHomePageWrapper } from '@retina-packages/retina-theme-bootstrap';
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import { AdvancingScienceCalloutWrapper, AmericanDataCalloutWrapper, HcpFooterBlockWrapper, OverlappingInheritedRetinalDiseaseWrapper, HcpHomePageMain } from './styles';
import TextCarousel from '@retina-packages/retina-theme-bootstrap/packages/components/Carousels/TextCarousel';
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import { CarouselResponceData } from '@retina-packages/retina-theme-bootstrap/packages/types/ImageCarouselType';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import { hcpHome } from './constants';
import Video from "@retina-packages/retina-theme-bootstrap/packages/images/dna-video-bg.mp4";
import { deriveMultiLogo, deriveSiteMenus, footRefHTMLBuilder, fullHTMLDataBuilder, textCarouselBuilder } from '../../../utils';
import retinaConfig from '../../../utils/retinaConfigs';

/** Rendering template for Emerging Science
 *
 * @param props props
 * @returns
 */
const EmergingScienceTemplate = (props: Props) => {

  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso;
  const pgContextsHHM = props.pageContext
  const htmlStaticFilesHHM = {
    nonSVGImages: pgContextsHHM.nonSVGImages,
    svgMediaImages: pgContextsHHM.svgMediaImages,
    allMediaDocument: pgContextsHHM.documents
  }
  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: hcpHome.hcpHomeMainMenu, siteFooterMenu: hcpHome.hcpHomeFooterMenu, addHCPPrefix: retinaConfig.addHcpPrefix });

  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name;
  const bannerTitle = ""
  const carouselTitle = hcpHome.hcpHomeCarousel
  const footerTitle = hcpHome.hcpFooter
  const exitPopupTitle = hcpHome.hcpExitPopup
  const hcpSwitcherTitle = hcpHome.hcpHCPSwitcher
  const topNavTitle = hcpHome.hcpTopNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs,
    carouselData
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
    carouselData: CarouselResponceData;
  } = reusableDataBuilders({blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle});

  const americanAcademyData = fullHTMLDataBuilder({ blocks, title: hcpHome.hcpAmericalData });
  // DNA carousel video Section Data
  const carouselSlideData: CarouselResponceData = textCarouselBuilder({ blocks, title: hcpHome.hcpHomeVideoCarousel });

  const advanceScienceDesc = fullHTMLDataBuilder({ blocks, title: hcpHome.hcpAdvancingScience });

  const overlappData: string = fullHTMLDataBuilder({ blocks, title: hcpHome.hcpOverlap });

  const hcpHomeReference = footRefHTMLBuilder({ blocks, title: hcpHome.hcpHomeFootRef });

  const siteLogos: any = deriveMultiLogo({ blocks, title: hcpHome.hcpSiteLogo })

  const footerCallOutData: string = fullHTMLDataBuilder({ blocks, title: hcpHome.hcpFooterCallout });

  return (
    <>
    <HcpHomePageMain>
      <HcpHomePageWrapper className='hcphomepagewrapper'>
        {props.pageContext !== null && props.pageContext.metaInfo !== null && (
          <MetaTagContainer metaData={props.pageContext.metaInfo} />
        )}
        <Layout
          title={"siteTitle"}
          modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
          location={props.location} data={mainMenu}
          mdata={footerMenu}
          footerData={footerText}
          audience={audience}
          footref={hcpHomeReference}
          exitPopData={exitData}
          hcplinks={hcpSwitcher}
          topNavigation={topNavs}
          siteLogos={siteLogos}
          gtmBackToTopLabel={hcpHome.backToTopGTM}
          backToTopContent={retinaConfig.backToTopContent}
          staticMediaFiles={htmlStaticFilesHHM}
          showRefContent={retinaConfig.showRefContent}
          hideRefContent={retinaConfig.hideRefContent}
          hcpValidate={retinaConfig.hcpValidate}
          preIndexUrl = {retinaConfig.preIndexUrl}
          hcpHomeUrl={retinaConfig.hcpHomeUrl}
          hcpPrefix={retinaConfig.hcpPrefix}
          siteHomeUrl={retinaConfig.siteHomeUrl}
          footerClassName={hcpHome.footerClassName}
          languageConfig={{... retinaConfig.langConfig, ...hcpHome.redirectLangUrl}}
          {... {...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
        >
          <PageWrapper className='pagewrapper hcp-homepage'>
            {carouselData && (
              <>
                <h1 className='visually-hidden'>{props.pageContext.metaInfo.title.split("|")[0]}</h1>
                <SimpleSlider data={carouselData} {...hcpHome.carousalGTMs} />
              </>
            )}
            <div className='o-container hcp-home-footer'>
              <AmericanDataCalloutWrapper className="hcpamerican">
                <div className="c-genetic--test home-intro o-mt-80 o-mb-80 o-block--center">
                  <HTMLRenderer html={americanAcademyData} data={htmlStaticFilesHHM} tagName={'div'} />
                </div>
              </AmericanDataCalloutWrapper>
              <TextCarousel carouselSlideData={carouselSlideData} Video={Video} {...hcpHome.videoCarousalGTMs}/>
              <AdvancingScienceCalloutWrapper className='c-advance-scienece o-text-center'>
              <HTMLRenderer html={advanceScienceDesc} data={htmlStaticFilesHHM} tagName={'div'} />
              </AdvancingScienceCalloutWrapper>
              <OverlappingInheritedRetinalDiseaseWrapper className='video-section o-inner video-section-home o-top-space'>
                <HTMLRenderer html={overlappData} data={htmlStaticFilesHHM} tagName={'div'} className={'video-section__inner-content'} />
              </OverlappingInheritedRetinalDiseaseWrapper>
            </div>
            <HcpFooterBlockWrapper className='o-container hcp-home-footer'>
            <HTMLRenderer html={footerCallOutData} data={htmlStaticFilesHHM} tagName="div" className='footer-block footer-block-bg' />
            </HcpFooterBlockWrapper>
          </PageWrapper>
        </Layout>
      </HcpHomePageWrapper>
      </HcpHomePageMain>
    </>
  )
};

export default EmergingScienceTemplate;

export const pageQuery = graphql`
  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ...HcpHomePageQuery
      }
    }
  }
`
