export const hcpHome: any = {
    hcpHomeMainMenu: "Main Navigational Menu HCP GCSO",
    hcpHomeFooterMenu: "Footer Navigation Menu HCP GCSO",
    hcpHomeCarousel: "Homepage Carousal HCP",
    hcpFooter: "Retina Global Footer HCP - Canada",
    hcpExitPopup: "Retina Canada HCP Exit Popup HTML",
    hcpHCPSwitcher: "HCP Switcher Modal Pop Up",
    hcpTopNav: "Top Navigational Menu HCP - Canada",
    hcpFooterCallout: "HCP Homepage Footer Navigation Callout HTML - Canada",
    hcpSiteLogo: "Site logos HCP",
    hcpAmericalData: "American Academy of Ophthalmology HTML - Canada",
    hcpAdvancingScience: "Advancing Science HTML - Canada",
    hcpOverlap: "Overlapping presentations of IRD HTML - Canada",
    hcpHomeFootRef: "HCP Home Reference",
    hcpHomeVideoCarousel: "DNA Video and Carousel",
    redirectLangUrl: {
      "redirectUrl": {
        "en": "/hcp/home",
        "fr": "/fr/hcp/home"
      }
    },
    backToTopGTM: "Back to top - Homepage",
    carousalGTMs: {
      playBtnGTM: {
        'className': 'gtm-link-internal',
        'data-gtm-event-label': 'hero image - play - hcp',
        'aria-label': 'Play slideshow'
      },
      pauseBtnGTM: {
        'className': 'gtm-link-internal',
        'data-gtm-event-label': 'hero image - pause - hcp',
        'aria-label': 'Pause slideshow'
      },
      playBtnAriaDescription: ' ',
      pauseBtnAriaDescription: ' '
    },
    videoCarousalGTMs: {
      playBtnGTM: {
        'className': 'gtm-link-internal',
        'data-gtm-event-label': 'new gene variants - play'
      },
      pauseBtnGTM: {
        'className': 'gtm-link-internal',
        'data-gtm-event-label': 'new gene variants - pause'
      }
    },
    footerClassName: "home-footer"
  }
