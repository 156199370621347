import styled from 'styled-components'
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const AdvancingScienceCalloutWrapper = styled.div`
	max-width: 780px;
	margin: 40px auto 80px;
	text-align: center;

	@media ${device.laptopMedium} {
    margin: 40px auto 30px;
    font-size: 2rem;
    line-height: 2.6rem;
	}

	.o-header,
	.o-header--h3 {
		h2 {
			font-size: 3.4rem;
			font-weight: 600;
			line-height: 4.7rem;
      font-family: "Gotham", Arial, Helvetica, sans-serif;
			margin: 61px 0 30px;
    	color: #8000BE;

			@media ${device.laptopMedium} {
				font-size: 2.2rem;
				line-height: 3.2rem;
				margin: 54px 0 20px;
				text-align: center;
			}
		}
	}

	.o-text--book {
		p {
			font-size: 2.2rem;
			font-weight: 500;
			line-height: 3.2rem;
			color: #000000;
      font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
			margin: 0;

			@media ${device.laptopMedium} {
				font-size: 1.8rem;
				line-height: 2.6rem;
			}
		}
	}

	.o-button {
		p {
			margin: 0;

			a {
				box-shadow: 0 3px 4px #d6d6d6;
				margin: 30px 0;
        font-family: "Gotham", Arial, Helvetica, sans-serif;
				background: #8000BE;
				color: #fff;
				border: 1px solid #fff;
				font-size: 2.2rem !important;
				font-weight: 500;
				line-height: 3.2rem;
				text-decoration: none;
				z-index: 1;
				position: relative;
				display: inline-block;
				vertical-align: top;
				overflow: hidden;
				padding: 10px 25px;
				letter-spacing: 0;
				border-radius: 6px;
				transition: all 0.3s ease;
    		cursor: pointer;

				&:hover {
					background: #fff;
					color: #8000BE;
					border-color: #fff;
					font-weight: 700;
					text-decoration: none;
				}

				&:after {
					content: '';
					display: inline-block !important;
					width: 24px;
					height: 18px;
					position: relative;
					top: 3px !important;
					left: 5px;
				}
			}
		}
	}
`;

export const AmericanDataCalloutWrapper = styled.div`
	&.hcpamerican {
		.c-genetic--test {
			&.home-intro {
				.o-paragraph {
					&.read-more {
						@media ${device.desktopsignup}{
							width: 100%;
						}
            p {
              a {
                &:hover {
                  text-decoration: underline;
                }
              }
            }
					}
				}
			}
		}
	}
	.c-genetic--test {
		padding: 35px 60px;
		position: relative;
		background-color: #f7f7f7;
		box-shadow: 0 4px 6px #cecece;

		@media ${device.laptopMedium} {
			padding: 25px;
			margin: 40px 0;
		}

		&:before {
			content: "";
			width: 16px;
			height: 100%;
			display: block;
			background: linear-gradient(-180deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
			position: absolute;
			top: 0;
			right: 100%;
			box-shadow: 0 4px 6px #cecece;
		}
		.read-more {
			p {
					margin-bottom: 24px !important;

			}
		}

		.o-paragraph {
      &:nth-child(2) {
        p{
          font-size: 1.8rem !important;
          line-height: 2.6rem !important;
          @media ${device.ipadLandscapemin} {
            font-size: 2.2rem !important;
            line-height: 3.2rem !important;
          }
        }
      }
			p {
				font-size: 1.8rem;
				font-weight: 500;
				line-height: 2.6rem;
				color: #000000;
				margin: 0;
				padding: 0;
				font-family: "Gotham", Arial, Helvetica, sans-serif;

				@media ${device.ipadLandscapemin} {
					font-size: 2.2rem;
    			line-height: 3.2rem;
				}

				&:first-child {
					font-weight: unset;
					margin-bottom: 24px;
				}

				span {
					color: #8000BE;
					text-transform: uppercase;
					font-weight: 500;
				}

				a {
					color: #8000BE !important;
					text-decoration: underline;
					font-weight: 500;
					cursor: pointer;

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
    				line-height: 2.2rem;
					}
				}
			}
		}
	}

	.o-mb-80 {
    margin-bottom: 8rem;
	}

	.o-mt-80 {
    margin-top: 8rem;
	}

	.o-block--center {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
	}

`;

export const HcpHomePageMain = styled.div`

.hcp-homepage{
	.slider-main {
		.c-hero-banner__content  {
			a {
				@media ${device.desktopsignup}{
					padding: 12px 22px;
				}
			}
		}
	}
  .dna-wrapper{
    .slick-list{
      @media ${device.laptopMedium}{
        overflow: visible;
      }
    }
  }
	&:before {
		@media ${device.desktopsignup}{
			left: -12px;
		}
	}
  hero-banner__c
	.slider-main {
		.slick-list {
				height: 1100px !important;
		}
	}
}

.slider-main{
  @media ${device.mobilemax} {
    height: 1135px;
  }
  height: 1100px;
  @media ${device.largeDesktop} {
    height: 1200px;
  }
  .c-hero-banner__content{
    padding-top: 300px;
    margin: 0px auto;
      @media ${device.laptopMedium}{
        bottom: 2%;
      }
	  @media ${device.ipadLandscapemin}{
        bottom: 10%;
	  }
    @media ${device.largeDesktop}{
      bottom: 12%;
  }

	  @media ${device.midDesktopMax}{
      padding-left: 25px;
      padding-right: 25px;
	  }


    p{
      @media ${device.mobileXSMax} {
        line-height: 2.4rem !important;
      }
      @media ${device.mobilemax}{
       margin: 10px auto !important;
       line-height: 2.6rem;
      }
      @media ${device.laptopMedium}{
        padding: 0px 7px;
		    margin: 25px auto;
      }
      @media ${device.ipadLandscapemin}{
		    margin: 30px auto;
      }
      @media ${device.desktopsignup}{
		    margin: 48px auto;
      }


      &:nth-child(2){
        @media ${device.mobileMedium}{
          margin: 10px auto;
         }
      }
    }
    a{
      @media ${device.laptopMedium}{
        max-width: 285px;
      }
      display: inline-block;
      max-width: unset;

        &:after{
          margin-left: 0px !important;
        }
    }
  }
  .c-hero-banner__pause-btn{
    background-color: #300064;
    width: 145px;
    height: 35px;
    font-size: 14px;
    @media ${device.ipadLandscapemin}{
      height: 50px;
      font-size: 18px;
    }
  }
  .slick-slider{
    .slick-slide{
      .c-hero-banner-heading-con{
        @media screen and (max-width: 320px) {
          bottom: 65% !important;
        }
        @media ${device.mobileMedium} {
          bottom: 57%;
        }
        @media ${device.tablet} and ${device.laptopMedium}{
          bottom: 47%;
        }
        @media ${device.ipadLandscapemin} {
          bottom: 50.6%;
        }
        @media ${device.laptop} {
          bottom: 45.1%;
          padding: 0 2rem;
        }

        @media ${device.laptopMedium}{
          padding: 0px 25px;
        }
        max-width: 1208px !important;
        .hero-heading-style{
          @media ${device.laptopMedium}{
            font-size: 3.2rem;
            line-height: 3.8rem;
            margin-bottom: 1rem;
          }
        }
      }
	  h2 {
		@media ${device.desktopsignup}{
			margin: 0 0 10px;
		}
	  }
	  .gatsby-image-wrapper {
		  img {
      height: 1100px;
      @media ${device.largeDesktop} {
        height: 1200px;
      }
		  }
	  }
    }
  }
}

.c-gene--disease{
  .c-gene--disease__content{
    @media ${device.laptopMedium}{
      height: 375px;
      background-size: 310px;
   }
    &:before{
      width: 237px;
      height: 237px;

      @media ${device.ipadLandscapemin}{
        width: 400px;
        height: 400px;
      }

    }
    .slick-slider{
      .c-gene--disease__content-inner {
        max-width: 374px;
        margin: 0px auto;
        padding: 0;
        width: 100%;
        display: inline-block;

        p{
          &:last-child{
            @media ${device.laptopMedium}{
              width: 200px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
  .c-gene--disease__pause-btn{
    background-color: #300064;
    color: #fff;
    opacity: 90%;
  }
}
`;

export const OverlappingInheritedRetinalDiseaseWrapper = styled.div`
	display: block;
	text-align: center;
	background-color: #8000BE;
	padding: 88px 100px 0 !important;

	@media ${device.laptopMedium} {
		padding: 1.6rem 1.6rem !important;
		max-width: 718px;
    font-size: 2rem;
    line-height: 2.6rem;
	}

	.video-section__inner-content {
    padding-bottom: 100px;

		@media ${device.laptopMedium} {
			padding-bottom: 20px;
		}

		.video-section__inner-content--white {
			background: #fff;
			padding: 88px 80px 80px;

			@media ${device.laptopMedium} {
				padding: 3rem 2.6rem 3.2rem 2.6rem;
			}

			.o-header,
			.o-header--h2 {
				h2 {
					padding-bottom: 40px;
					color: #8000BE;
					font-size: 4.2rem;
					font-weight: 600;
					font-family: "Gotham", Arial, Helvetica, sans-serif;
					margin: 0;

					@media ${device.laptopMedium} {
						padding-bottom: 0;
						text-align: center;
						font-size: 2.5rem;
						line-height: 3rem;
					}
				}
			}

			.o-paragraph {
				p {
					color: #000000;
					font-size: 2.2rem;
					line-height: 3.2rem;
					text-align: center;
					max-width: 768px;
					margin: 0 auto;
					font-weight: 500;
          font-family: "Gotham", Arial, Helvetica, sans-serif;

					@media ${device.laptopMedium} {
						font-size: 1.4rem;
						line-height: 2.6rem;
						padding-top: 25px;
					}
					sup {
						position: relative;
						font-size: 60%;
						line-height: 0;
						vertical-align: baseline;
						top: -0.5em;
						font-family: "Gotham", Arial, Helvetica, sans-serif;
					}
				}
			}

			.video-section__image {
				margin: 20px 0 0;

				img {
					max-width: 100%;
				}
			}

			.image-caption {
				p {
					color: #000000;
					font-size: 1.6rem;
					line-height: 2rem;
					text-align: center;
					max-width: 768px;
					margin: 40px auto 0;
          font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
					font-weight: 500;

					@media ${device.laptopMedium} {
						font-size: 1.4rem;
						padding-top: 25px;
    				margin: 0 !important;
					}
				}
			}

			.o-button {
				p {
					margin: 0;

					a {
						box-shadow: 0 3px 4px #d6d6d6;
						margin: 40px 0;
            font-family: "Gotham", Arial, Helvetica, sans-serif;
						background: #8000BE;
						color: #fff;
						border: 1px solid #fff;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;
						text-decoration: none;
						z-index: 1;
						position: relative;
						display: inline-block;
						vertical-align: top;
						overflow: hidden;
						padding: 10px 25px;
						letter-spacing: 0;
   					border-radius: 6px;
						transition: all 0.3s ease;
    				cursor: pointer;

						@media ${device.laptopMedium} {
							margin: 32px 0 10px 0;
							font-size: 1.8rem;
							line-height: 2.6rem;
						}

						&:hover {
							background: #fff;
							color: #8000BE;
							border-color: #fff;
							font-weight: 700;
						}

						&:after {
							content: '';
							display: inline-block !important;
							width: 24px;
							height: 18px;
							position: relative;
							top: 3px;
							left: 5px;

							@media ${device.laptopPros} {
								display: block !important;
								margin: 10px auto 0;
								left: 0;
								top: 0;
							}
						}
					}
				}
			}
		}
	}
`;

export const HcpFooterBlockWrapper = styled.div`
	.footer-block {
		padding: 0 100px;
		display: flex;

		&.footer-block-bg {
			background-color: #8000BE;
		}

		@media ${device.ipadLandscape} {
			padding: 0 20px;
			display: block;
			margin-top: -1px;
		}

		.footer-block--row {
			margin: auto;
			display: flex;

			@media ${device.ipadLandscape} {
				display: block;
			}

			.footer-inner-block {
				border-radius: 10px;
				background-color: #fff;
				padding: 25px 20px 120px;
				position: relative;
				margin: 0 15px;
				z-index: 992;
				box-shadow: none;

				@media ${device.ipadLandscape} {
					width: 100%;
					margin: 30px auto 0;
					padding: 25px 20px 30px;
				}

				&:first-child {
					margin-left: 0;

					@media ${device.ipadLandscape} {
						margin-left: auto;
						margin-top: 0;
					}
				}

				&:last-child {
					margin-right: 0;
				}

				&:before {
					content: '';
					background: #00FFD9;
					width: 100%;
					height: 16px;
					position: absolute;
					top: 0;
					left: 0;
					border-radius: 9px 9px 0 0;
				}

				.footer-inner-block__small-icon {
					img {
						margin: auto;
						text-align: center;
						display: block;
						padding: 10px 0 20px 0;
						height: 100px;
						width: auto;
						max-width: 100%;
					}
				}

				.o-common-title {
					p,
					h1,
					h2,
					h3 {
						margin: 0;
						font-weight: 700;
						min-height: unset !important;
						font-size: 2.7rem;
						line-height: 3.8rem;
						color: #8000BE;
						text-align: center;

						@media ${device.ipadLandscape} {
							font-size: 2rem;
							line-height: 2.2rem;
						}
					}
				}

				.o-common-para {
					p {
						margin: 0;
						text-align: center;
						padding: 24px 0 0 0;
            font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
						font-size: 1.8rem !important;
						line-height: 2.6rem !important;
						color: #000000;

						@media ${device.ipadLandscapemin} {
							font-size: 2.2rem !important;
							line-height: 3.2rem !important;
						}
					}
				}

				.o-button {
					p {
						margin: 0;

						a {
							position: absolute;
							width: 90%;
							bottom: 25px;
							text-align: center;
							box-shadow: 0 3px 4px #d6d6d6;
							display: block;
							justify-content: center;
							align-items: center;
							font-size: 1.9rem;
							background: #8000BE;
							color: #fff;
							border: 1px solid #fff;
							font-family: "Gotham", Arial, Helvetica, sans-serif;
							font-weight: 500;
							line-height: 3.2rem;
							text-decoration: none;
							z-index: 1;
							vertical-align: top;
							overflow: hidden;
							padding: 10px 25px;
							letter-spacing: 0;
							border-radius: 6px;
							transition: all 0.3s ease;
							cursor: pointer;

							@media ${device.ipadLandscape} {
								position: relative;
								left: 0;
								bottom: 0;
								margin-top: 32px;
								width: 100%;
								display: block;
								padding: 10px;
								font-size: 18px;
								line-height: 2.6rem;
							}

							&:hover {
								background: #fff;
								color: #8000BE;
								border-color: #fff;
								font-weight: 700;
								text-decoration: none;
							}

							&:after {
								content: '';
								display: inline-block;
								width: 24px;
								height: 18px;
								position: relative;
								top: 2px !important;
								left: 5px;

								@media ${device.ipadLandscape} {
									display: block;
									margin: 10px auto 0;
									left: 0;
									top: 0;
								}
							}
						}
					}
				}
			}
		}
	}
`;
